import React from 'react'
import CookieConsent from "react-cookie-consent";
import cookie from '../../images/cookie.svg';

export default () =>
  <footer className="footer container">
        <CookieConsent
            location="bottom"
            buttonText="Am înțeles"
            cookieName="cesavizitamCookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "#FF4A4A", color: "#FFF", fontSize: "13px" }}
            buttonClasses="cookie-button"
            expires={150}
        >
            <img src={cookie} alt={'cookie'}/>Acest site folosește cookie-uri. Continuarea navigării implică acceptarea lor.
        </CookieConsent>
    <p>{ new Date().getFullYear() } &copy;  <a href="//www.cesavizitam.ro">cesavizitam.ro</a></p>
  </footer>
