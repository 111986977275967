import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { stack as Menu } from 'react-burger-menu'
import close from '../../images/close.svg'

class Header extends React.Component {
    componentDidMount() {

    }
    render() {
        const { logo } = this.props;
        return (
            <div>
                <div className="hamburger-menu">
                    <Menu width={'100%'} customCrossIcon={<img src={close} alt="close" />}>
                        <a href="/">
                            <Img className="logo"
                                fluid={logo.childImageSharp.fluid}
                                alt="Ce sa vizitam"
                            />
                        </a>
                        <Link to='/'>Acasă</Link>
                        <Link to='/drumetii'>Drumeții</Link>
                        <Link to='/la-pas-prin-romania'>La pas prin România</Link>
                        <Link to='/calatorii-europa'>Călătorii prin Europa</Link>
            {/* <Link to='/scandinavia'>Scandinavia</Link> */}
                        <Link to='/contact'>Contact</Link>
                    </Menu>
                </div>
                <div className="header">
                    <a href="/">
                        <Img className="logo"
                            fluid={logo.childImageSharp.fluid}
                            alt="Ce sa vizitam"
                        />
                    </a>
                    <div className='menu'>
                        <Link to='/'>Acasă</Link>
                        <Link to='/drumetii'>Drumeții</Link>
                        <Link to='/la-pas-prin-romania'>La pas prin România</Link>
                        <Link to='/calatorii-europa'>Călătorii prin Europa</Link>
              {/* <Link to='/scandinavia'>Scandinavia</Link> */}
                        <Link to='/contact'>Contact</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header