import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import '../../stylesheets/main.scss'
import Header from './Header'

const staticQuery = graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
        prismic{
            allBlog_homes{
                edges{
                    node{
                        image
                        imageSharp {
                            childImageSharp {
                            fluid(quality: 100) {
                                base64
                                aspectRatio
                                sizes
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                                }
                            }
                        }
                    }
                }
            }
          }
    }`

export default props => (
  <StaticQuery
    query={`${staticQuery}`}
    render={data => <Layout data={data} {...props} />}
  />
)

const Layout = (props) => {
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title
  const logo = props.data.prismic.allBlog_homes.edges[0].node.imageSharp;
  // const description = props.data.site.siteMetadata.description
  const description = "Un blog de călătorie care iși propune să poarte cititorii prin locuri frumoase descrise în articole și însoțite de fotografii"

  // Load the Prismic edit button
  if (typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton()
  }

  return (
    <Fragment>
      <Helmet>
        <html lang="ro" />
        <meta charSet="utf-8" />
        <meta http-equiv="Cache-Control" content="public, max-age=0, must-revalidate" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
      </Helmet>
      <main onContextMenu={e => e.preventDefault()} role="main">
        <div className="content">
          <Header logo={logo}/>
          {props.children}
        </div>
      </main>
      <Footer />
    </Fragment>
  )
}
